import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doFetch, apiError, doPut } from '../redux/api/actions';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { CATEGORIES, CURRENT_RECIPE, RECIPE_UPDATE } from '../redux/api/constants';
import withLayout from './withLayout';

function Recipe() {
    const { category_id, recipe_id } = useParams();
    const [name, updateName] = useState("");
    const [source, updateSource] = useState("");
    const [ingredients, updateIngredients] = useState("");
    const [directions, updateDirections] = useState("");
    const [selected_category_id, updateCategory] = useState(1);

    const dispatch = useDispatch()
    const currentRecipe = useSelector(state => state.API.currentRecipe);
    const me = useSelector(state => state.API.me);
    const authToken = useSelector(state => state.Auth.accessToken);
    const categories = useSelector(state => state.API.categories);

    useEffect(() => {
        dispatch(doFetch(
            CURRENT_RECIPE,
            `recipes/${recipe_id}`
        ))

        dispatch(doFetch(
            CATEGORIES,
            'categories',
        ))

    }, [dispatch, recipe_id]);



    const updateRecipe = (e) => {
        e.preventDefault();
        if (me.id !== currentRecipe.user_id && !me.is_superuser) {
            dispatch(apiError("You are not authorized to edit this recipe"));
            return;
        }

        dispatch(doPut(RECIPE_UPDATE, `recipes/${recipe_id}`, authToken, {
            name: name,
            source: source,
            ingredients: ingredients,
            directions: directions,
            category_id: category_id,
            food_image: null
        }
        ))

    }

    useEffect(() => {
        if (currentRecipe) {
            updateName(currentRecipe.name || "");
            updateSource(currentRecipe.source || "");
            updateIngredients(currentRecipe.ingredients || "");
            updateDirections(currentRecipe.directions || "");
            updateCategory(currentRecipe.category_id || 1);
        }
    }, [currentRecipe]);

    return (
        <div >
            {currentRecipe && (
                <>
                    <Link to={`/categories/${category_id}`}>{currentRecipe.category}</Link> / {name}
                    <form onSubmit={updateRecipe}>
                        <fieldset>
                            <div className="form-group">
                                <label htmlFor="name" className="form-label"> Recipe Name</label>
                                <input type="text"
                                    id="name"
                                    name="name"
                                    value={name}
                                    className="form-control"
                                    onChange={(e) => updateName(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="source" className="form-label">Source</label>
                                <input type="text"
                                    id="source"
                                    name="source"
                                    className="form-control"
                                    value={source}
                                    onChange={(e) => updateSource(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="ingredients" className="form-label">Ingredients</label>
                                <textarea
                                    id="ingredients"
                                    name="ingredients"
                                    className="form-control"
                                    value={ingredients}
                                    rows={2 + ingredients.split("\n").length}
                                    onChange={(e) => updateIngredients(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="directions" className="form-label">Directions</label>
                                <textarea
                                    id="directions" name="directions"
                                    className="form-control"
                                    value={directions}
                                    rows={2 + directions.split("\n").length}
                                    onChange={(e) => updateDirections(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="category" className="form-label">Category</label>
                                <select className="form-select" id="category" value={selected_category_id} onChange={(e) => updateCategory(e.target.value)}>
                                    {categories && categories.map((category, index) => (
                                        <option key={index} value={category.id}>{category.title}</option>
                                    ))}
                                </select>

                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn mt-4 btn-primary">Update Recipe</button>
                            </div>
                        </fieldset>
                    </form>
                </>
            )}
        </div >
    );
}
export default withLayout(Recipe);