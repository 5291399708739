import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doFetch, doCreate } from '../redux/api/actions';
import { useNavigate } from "react-router-dom";
import { CATEGORIES, RECIPE_CREATE } from '../redux/api/constants';
import withLayout from './withLayout';

function CreateRecipe() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const categories = useSelector(state => state.API.categories);
    const authToken = useSelector(state => state.Auth.accessToken);

    const [name, updateName] = useState("");
    const [source, updateSource] = useState("");
    const [ingredients, updateIngredients] = useState("");
    const [directions, updateDirections] = useState("");
    const [category_id, updateCategory] = useState(1);

    useEffect(() => {
        dispatch(doFetch(
            CATEGORIES,
            'categories',
        ))
    }, [dispatch]);


    const createRecipe = (e) => {
        e.preventDefault();
        dispatch(doCreate(
            RECIPE_CREATE,
            'recipes',
            authToken,
            {
                name: name,
                source: source,
                ingredients: ingredients,
                directions: directions,
                category_id: category_id,
                food_image: null
            },
            navigate
        ))
    }

    return (
        <div>
            <h1>Create a new recipe</h1>
            <form onSubmit={createRecipe}>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Recipe Name</label>
                    <input type="text" className="form-control" id="name" value={name} onChange={(e) => updateName(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="source" className="form-label">Source</label>
                    <input type="text" className="form-control" id="source" value={source} onChange={(e) => updateSource(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="ingredients" className="form-label">Ingredients</label>
                    <textarea className="form-control" id="ingredients" value={ingredients} onChange={(e) => updateIngredients(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="directions" className="form-label">Directions</label>
                    <textarea className="form-control" id="directions" value={directions} onChange={(e) => updateDirections(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="category" className="form-label">Category</label>
                    <select className="form-select" id="category" value={category_id} onChange={(e) => updateCategory(e.target.value)}>
                        {categories && categories.map((category, index) => (
                            <option key={index} value={category.id}>{category.title}</option>
                        ))}
                    </select>
                </div>
                <button type="submit" className="btn btn-primary">Create Recipe</button>
            </form>
        </div>
    );
}

export default withLayout(CreateRecipe);