import {
	DELETE,
	FETCH,
	PATCH,
	PUT,
	CREATE,
	GOT_RESPONSE,
	ERROR,
	SET_MESSAGE,
} from './constants';

export const doFetch = (dataType, path, accessToken) => ({
	type: FETCH,
	dataType,
	path,
	accessToken,
})

export const doPut = (dataType, path, accessToken, data) => ({
	type: PUT,
	dataType,
	path,
	accessToken,
	data,
})

export const doPatch = (dataType, path, accessToken, data) => ({
	type: PATCH,
	dataType,
	path,
	accessToken,
	data,
})

export const doDelete = (dataType, path, accessToken) => ({
	type: DELETE,
	dataType,
	path,
	accessToken,
})

export const doCreate = (dataType, path, accessToken, data, navigate) => ({
	type: CREATE,
	dataType,
	path,
	accessToken,
	data,
	navigate,
})


export const gotResponse = (responseType, response) => ({
	type: GOT_RESPONSE,
	responseType,
	response,
})

export const apiError = (error) => ({
	type: ERROR,
	error,
})

export const setMessage = (message) => ({
	type: SET_MESSAGE,
	message
})