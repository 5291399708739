import React from "react";
import { useSelector } from 'react-redux';

const messages = {
    "PASSWORD_RESET": "If you have entered a valid email, password reset instructions have been sent to your email!  Please check your email and follow the instructions.",
}

function InfoMessage() {
    const authMessage = useSelector(state => state.Auth.message);
    const apiMessage = useSelector(state => state.API.message);
    return (
        <>
            {authMessage &&
                <div className="alert alert-success" role="alert">
                    {messages[authMessage] ? messages[authMessage] : authMessage.toString()}
                </div>
            }
            {apiMessage &&
                <div className="alert alert-success" role="alert">
                    {messages[apiMessage] ? messages[apiMessage] : apiMessage.toString()}
                </div>}
        </>

    );
}

export default InfoMessage;
