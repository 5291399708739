import React from 'react';
import NavBar from './navbar';
import Footer from './footer';

const withLayout = (WrappedComponent) => {
    return (props) => {
        return (
            <>
                <NavBar />
                <div className="container-fluid min-vh-100">
                    <WrappedComponent {...props} />
                </div >
                <Footer />
            </>
        );
    };
};

export default withLayout;
