import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from './api';

import { DELETE, FETCH, PUT, PATCH, CREATE, RECIPE_CREATE, ADMIN_GET_USERS, ADMIN_DELETE_USER } from './constants';
import { gotResponse, doFetch, apiError, setMessage } from './actions';

function* getStuff(action) {
	try {
		const response = yield call(api.get, {
			path: action.path,
			accessToken: action.accessToken,
		})
		yield put(gotResponse(action.dataType, response))
	} catch (err) {
		yield put(apiError(err));
	}
}


function* createStuff(action) {
	try {
		const response = yield call(api.create, {
			path: action.path,
			accessToken: action.accessToken,
			data: action.data,
		})
		yield put(gotResponse(action.dataType, response))
		if (action.dataType === RECIPE_CREATE) {
			action.navigate(`/categories/${response.recipe.category_id}/recipes/${response.recipe.id}`)
		}

	} catch (err) {
		yield put(apiError(err));
	}

}

function* putStuff(action) {
	try {
		const response = yield call(api.update, {
			path: action.path,
			accessToken: action.accessToken,
			data: action.data,
		})
		yield put(gotResponse(action.dataType, response))
	} catch (err) {
		yield put(apiError(err));
	}

}

function* patchStuff(action) {
	try {
		const response = yield call(api.patch, {
			path: action.path,
			accessToken: action.accessToken,
			data: action.data,
		})
		yield put(gotResponse(action.dataType, response))
	} catch (err) {
		yield put(apiError(err));
	}
}

function* deleteStuff(action) {
	try {
		const response = yield call(api.delete, {
			path: action.path,
			accessToken: action.accessToken,
		})
		if (action.dataType === ADMIN_DELETE_USER) {
			// make a message say a user was deleted. 
			// update the admin user list by fetching from the db again:
			yield put(doFetch(ADMIN_GET_USERS, 'users', action.accessToken))
			yield put(setMessage('User Deleted Successfully: ' + action.path.replace('users/', '')))
		} else {
			yield put(gotResponse(action.dataType, response))
		}
	} catch (err) {
		yield put(apiError(err));
	}
}

export function* watchAPIActions() {
	yield takeEvery(FETCH, getStuff);
	yield takeEvery(PUT, putStuff);
	yield takeEvery(CREATE, createStuff);
	yield takeEvery(PATCH, patchStuff);
	yield takeEvery(DELETE, deleteStuff);
}

function* apiSaga() {
	yield all([
		fork(watchAPIActions),
	])
}

export default apiSaga;
