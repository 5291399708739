import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { doFetch } from '../redux/api/actions';
import { logout } from '../redux/auth/actions';
import { ME } from '../redux/api/constants';
import Error from './auth/error';
import Info from './auth/info';
import 'bootstrap-icons/font/bootstrap-icons.css';


function UserDropDown({ user }) {
	const dispatch = useDispatch();
	const signout = (e) => {
		e.preventDefault();
		console.log("signout!")
		dispatch(logout());
	}
	return (
		<>
			{user.is_superuser &&
				<li className="nav-item">
					<Link to="/admin" className="nav-link">Admin</Link>
				</li>
			}
			<li className="nav-item dropdown">
				<button className="btn nav-link dropdown-toggle" id="userDropDownMenu"
					data-bs-toggle="dropdown" aria-expanded="false" aria-label="Toggle navigation">
					<i className="bi bi-person-circle"></i>
				</button>
				<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdownMenu">
					<li><Link to="/profile" className="dropdown-item" onClick="/profile">Profile</Link></li>
					<li><Link to="/signout" className="dropdown-item" onClick={signout}>Logout</Link></li>
				</ul>
			</li>
		</>
	)
}


function NavBar({ selected, error, message }) {
	const dispatch = useDispatch()
	const authToken = useSelector(state => state.Auth.accessToken);
	const me = useSelector(state => state.API.me);
	const loading = useSelector(state => state.API.loading);

	useEffect(() => {
		if (authToken) {
			dispatch(doFetch(
				ME,
				'users/me',
				authToken
			))
		}
	}, [dispatch, authToken]);


	return (
		<>
			<nav className="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
				<div className="container-fluid">
					<a className="navbar-brand" href="/">
						<img src="/images/cw-logo.png" alt="CookingWright" height="24" className="d-inline-block align-text-top" />
					</a>
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarToggler">
						<ul className="navbar-nav ml-auto mb-2 mb-lg-0 d-flex">
							<li className="nav-item">
								<Link to="/about" className={selected === "about" ? "nav-link active" : "nav-link"} > About</Link>
							</li>
						</ul>
						<ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex">
							{loading ? <li className="nav-item">
								<	div className="spinner-border text-primary" role="status"></div>
							</li> :
								me && Object.entries(me).length > 0 ?
									<>
										<UserDropDown user={me} />
									</>
									:
									<>
										<li className="nav-item">
											<Link to="/signin" className={selected === "signin" ? "nav-link active" : "nav-link"}>Sign In</Link>
										</li>
									</>


							}
						</ul>
					</div>


				</div>
			</nav >
			<Error />
			<Info />
		</>
	);
}

export default NavBar;