import React, { useState } from 'react';

const EditCategoryModal = ({ show, handleClose, category, handleSave }) => {
    const [title, setTitle] = useState(category.title);

    const handleSubmit = () => {
        handleSave({ ...category, title });
        handleClose();
    };

    return (
        <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit Category</h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="formCategoryTitle" className="form-label">Category Title</label>
                            <input
                                type="text"
                                className="form-control"
                                id="formCategoryTitle"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditCategoryModal;
