import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doFetch } from '../redux/api/actions';
import { CATEGORIES, ME } from '../redux/api/constants';
import { Link, useNavigate } from 'react-router-dom';
import withLayout from './withLayout';
import { MakeSearchBar, doSearch } from "./searchbar";


function CategoryList({ categories }) {
    return (
        <ul className="list-group mt-5">
            {categories && categories.map((category, index) => (
                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    <Link to={`/categories/${category.id}`}>
                        {category.title}
                    </Link>
                </li>
            ))}
        </ul>
    )
}





function Home() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const authToken = useSelector(state => state.Auth.accessToken);
    const categories = useSelector(state => state.API.categories);
    const me = useSelector(state => state.API.me);



    useEffect(() => {
        if (authToken) {
            dispatch(doFetch(
                ME,
                'users/me',
                authToken
            ))
        }
    }, [dispatch, authToken]);

    useEffect(() => {
        dispatch(doFetch(
            CATEGORIES,
            'categories',
        ))
    }, [dispatch]);

    return (
        <>
            <div className="container min-vh-100">
                <MakeSearchBar searchFunc={(keyword) => doSearch(navigate, keyword)} />
                <CategoryList categories={categories} user={me} />
                {me && (
                    <Link to="/recipes/new" className="btn btn-primary mt-4">
                        Add a new recipe
                    </Link>
                )}
            </div>
        </>
    );
}
export default withLayout(Home);