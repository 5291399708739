
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doFetch } from '../redux/api/actions';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { CURRENT_RECIPE } from '../redux/api/constants';
import withLayout from './withLayout';

function Recipe() {
    const { category_id, recipe_id } = useParams();
    const dispatch = useDispatch()
    const currentRecipe = useSelector(state => state.API.currentRecipe);
    const me = useSelector(state => state.API.me);

    useEffect(() => {
        dispatch(doFetch(
            CURRENT_RECIPE,
            `recipes/${recipe_id}`
        ))
    }, [dispatch, recipe_id]);

    return (
        <>
            {currentRecipe && (
                <>
                    <Link to={`/categories/${category_id}`}>{currentRecipe.category}</Link> / {currentRecipe.name}
                    <h1 className="mt-3">{currentRecipe.name}</h1>
                    <div>
                        source: {currentRecipe.source ? currentRecipe.source : "Unknown"}
                        <br />
                        last updated by <Link to={`/users/${currentRecipe.username}`}>{currentRecipe.username}</Link>
                        &nbsp;
                        {currentRecipe.updated_at}
                    </div>
                    <h2>Ingredients</h2>
                    <ul>
                        {currentRecipe.ingredients && currentRecipe.ingredients.split("\n").filter(ingredient => ingredient.trim() !== "").map((ingredient, index) => (
                            <li key={index}>{ingredient}</li>
                        ))}
                    </ul>
                    <h2>Directions</h2>
                    <ol>
                        {currentRecipe.directions && currentRecipe.directions.split("\n").filter(direction => direction.trim() !== "").map((direction, index) => (
                            <li key={index}>{direction}</li>
                        ))}
                    </ol>
                    {me && (me.username === currentRecipe.username || me.is_superuser) && (
                        <div>
                            <Link to={`/categories/${category_id}/recipes/${recipe_id}/edit`} className="btn btn-primary">Edit</Link>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
export default withLayout(Recipe);