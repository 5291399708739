export const FETCH = 'FETCH';
export const PUT = 'PUT';
export const CREATE = 'CREATE';
export const PATCH = 'PATCH';
export const DELETE = 'DELETE';
export const GOT_RESPONSE = 'GOT_RESPONSE';
export const ERROR = 'ERROR';
export const SET_MESSAGE = 'SET_MESSAGE';

/* user methods */
export const ME = 'ME';
export const UPDATE_ME = 'UPDATE_ME'
export const GET_PUBLIC_USER = 'GET_PUBLIC_USER';

/* data type methods */
export const CATEGORIES = 'CATEGORIES';
export const CURRENT_CATEGORY = 'CURRENT_CATEGORY';

/* Recipe CRUD methods */
export const CURRENT_RECIPE = 'CURRENT_RECIPE';
export const RECIPES = 'RECIPES';
export const RECIPE_UPDATE = 'UPDATE_RECIPE';
export const RECIPE_CREATE = 'CREATE_RECIPE';
export const RECIPE_SEARCH = 'SEARCH_RECIPE';

/* Admin methods */
export const ADMIN_GET_USERS = 'ADMIN_GET_USERS'
export const ADMIN_UPDATE_USERS = 'ADMIN_UPDATE_USERS'
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER'
export const ADMIN_CREATE_CATEGORY = 'ADMIN_CREATE_CATEGORY'
export const ADMIN_EDIT_CATEGORY = 'ADMIN_EDIT_CATEGORY'
export const ADMIN_DELETE_CATEGORY = 'ADMIN_DELETE_CATEGORY'

