import React from "react";
import { Navigate, Outlet, Route, Routes, BrowserRouter } from 'react-router-dom';
import RecipeSearch from '../components/searchResult';
import About from '../components/about';
import Categories from "../components/categories";
import ForgotPassword from "../components/auth/forgot";
import GoogleCallback from "../components/auth/callbacks/google";
import Home from '../components/home';
import NotFound from '../components/notfound';
import Profile from "../components/profile"
import Recipe from "../components/recipe";
import EditRecipe from "../components/editRecipe";
import CreateRecipe from "../components/createRecipe";
import CreateCategory from "../components/admin/createCategory";
import ResetPassword from "../components/auth/reset";
import SignUp from '../components/auth/signup';
import SignOut from '../components/auth/signout';
import SignIn from '../components/auth/signin';
import PublicProfile from "../components/publicProfile";
import Admin from "../components/admin";
import { useSelector } from "react-redux";
import Privacy from "../components/privacy";


const PrivateOutlet = () => {
    const authToken = useSelector(state => state.Auth.accessToken);
    if (authToken) {
        return <Outlet />;
    } else {
        return <Navigate to="/signin" />;
    }
}

const allRoutes = () =>
    <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/signin" element={<SignIn />} />
            <Route exact path="/signout" element={<SignOut />} />
            <Route exact path="/callback/google" element={<GoogleCallback />} />
            <Route exact path="/auth/forgot" element={<ForgotPassword />} />
            <Route exact path="/auth/reset" element={<ResetPassword />} />
            <Route exact path="/categories/:id" element={<Categories />} />
            <Route exact path="/categories/:category_id/recipes/:recipe_id" element={<Recipe />} />
            <Route exact path="/users/:username" element={<PublicProfile />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path = "/recipes" element={<RecipeSearch />} />
            <Route path="*" element={<NotFound />} />
            {/* Private Routes */}
            <Route exact path="/profile" element={<PrivateOutlet />} >
                <Route exact path="/profile" element={<Profile />} />
            </Route>
            <Route exact path="/categories/:category_id/recipes/:recipe_id/edit" element={<PrivateOutlet />} >
                <Route exact path="/categories/:category_id/recipes/:recipe_id/edit" element={<EditRecipe />} />
            </Route>
            <Route exact path="/recipes/new" element={<PrivateOutlet />} >
                <Route exact path="/recipes/new" element={<CreateRecipe />} />
            </Route>
            <Route exact path="/admin" element={<PrivateOutlet />} >
                <Route exact path="/admin" element={<Admin />} />
            </Route>
            <Route exact path="/admin/categories/new" element={<PrivateOutlet />} >
                <Route exact path="/admin/categories/new" element={<CreateCategory />} />
            </Route>
            <Route exact path="/admin/categories" element={<PrivateOutlet />} >
                <Route exact path="/admin/categories" element={<Admin />} />
            </Route>
        </Routes>
    </BrowserRouter>
export default allRoutes