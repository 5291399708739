import React, { useEffect } from 'react';
import SignUpButton from './signupbutton';
import { useDispatch, useSelector } from 'react-redux';
import { registerRequest, oauthSignupGoogleRequest } from '../../redux/auth/actions';
import { useNavigate, Link } from 'react-router-dom';
import withLayout from '../withLayout';

function SignUp() {
	const dispatch = useDispatch()
	const navigate = useNavigate();
	const accessToken = useSelector(state => state.Auth.accessToken);

	// Use a useEffect to handle navigation when userData changes
	useEffect(() => {
		if (accessToken) {
			navigate('/signin');
		}
	}, [accessToken, navigate]);

	const signUpMethod = (params) => {
		dispatch(registerRequest(params));
	}

	const oauthSignUpMethod = (params) => {
		dispatch(oauthSignupGoogleRequest(params));
	}

	return (
		<div className="my-5">
			<h1>Sign Up</h1>
			<SignUpButton requestFunc={signUpMethod} oauthFunc={oauthSignUpMethod} buttonType="Sign Up" />
			<div className="mt-3 text-center">
				Already Have an Account? <Link to="/signin">Sign In</Link>
			</div>
		</div>
	)
}

export default withLayout(SignUp);