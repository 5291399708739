// sagas/authSaga.js
import { takeLatest, call, put } from 'redux-saga/effects';
import {
	AUTH_REQUEST,
	LOGOUT,
	REGISTER_REQUEST,
	FORGOT_PASSWORD_REQUEST,
	SEND_VERIFICATION_CODE_REQUEST,
	RESET_PASSWORD_REQUEST,
	GET_GOOGLE_OAUTH_URL,
	SEND_GOOGLE_OAUTH_CALLBACK,
} from './constants';

import {
	authSuccess, authFailure,
	registerRequestSuccess,
	forgotPasswordSuccess,
	resetPasswordSuccess,
	getGoogleOAuthURLSuccess,
	sendVerificationCodeSuccess,
	logoutSuccess,
	sendGoogleOAuthCallbackSuccess,
} from './actions';

import {
	forgotPassword,
	getGoogleOAuthUrl,
	loginRequest,
	logoutRequest,
	registerNewUser,
	sendVerificationCode,
	sendGoogleOAuthCallback,
	sendResetPassword,
} from './api';

// register
function* handleRegisterRequest(action) {
	try {
		const user = yield call(registerNewUser, action.payload);
		yield put(registerRequestSuccess(user));
		action.payload.navigate("/signin");
	} catch (error) {
		console.log(error)
		yield put(authFailure(error));
	}
}

// sign in
function* handleAuthRequest(action) {
	try {
		const user = yield call(loginRequest, action.payload);
		yield put(authSuccess(user));
	} catch (error) {
		yield put(authFailure(error));
	}
}

// logout
function* handleLogout() {
	try {
		yield call(logoutRequest);
		yield put(logoutSuccess());
	} catch (error) {
		yield put(authFailure(error));
	}
}

function* handleForgotPasswordRequest(action) {
	try {
		yield call(forgotPassword, action.email);
		yield put(forgotPasswordSuccess());
	} catch (error) {
		console.error('Forgot password request failed:', error);
	}
}

function* handleSendVerificationCodeRequest(action) {
	try {
		yield call(sendVerificationCode, action.payload);
		yield put(sendVerificationCodeSuccess());
	} catch (error) {
		console.error('Sending verification code failed:', error);
	}
}

function* handleGetGoogleOAuthUrlRequest(action) {
	try {
		const response = yield call(getGoogleOAuthUrl, action.payload);
		yield put(getGoogleOAuthURLSuccess(response))
	} catch (error) {
		console.error('Getting Google OAuth code failed:', error);
		yield put(authFailure(error));
	}
}

function* handleSendGoogleOAuthCallbackRequest(action) {
	try {
		const response = yield call(sendGoogleOAuthCallback, action.payload);
		yield put(sendGoogleOAuthCallbackSuccess(response))
	} catch (error) {
		console.error('Sending Google OAuth code failed:', error);
		yield put(authFailure(error));
	}
}

function* handleSendResetPasswordRequest(action) {
	try {
		const response = yield call(sendResetPassword, action.payload);
		yield put(resetPasswordSuccess(response))
	} catch (error) {
		console.error('Sending reset password failed:', error);
		yield put(authFailure(error));
	}
}


function* authSaga() {
	yield takeLatest(AUTH_REQUEST, handleAuthRequest);
	yield takeLatest(LOGOUT, handleLogout);
	yield takeLatest(REGISTER_REQUEST, handleRegisterRequest);
	yield takeLatest(FORGOT_PASSWORD_REQUEST, handleForgotPasswordRequest);
	yield takeLatest(SEND_VERIFICATION_CODE_REQUEST, handleSendVerificationCodeRequest);
	yield takeLatest(GET_GOOGLE_OAUTH_URL, handleGetGoogleOAuthUrlRequest);
	yield takeLatest(SEND_GOOGLE_OAUTH_CALLBACK, handleSendGoogleOAuthCallbackRequest);
	yield takeLatest(RESET_PASSWORD_REQUEST, handleSendResetPasswordRequest);
}

export default authSaga;