import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { doFetch, doDelete, doPatch } from '../../redux/api/actions';
import { ME, CATEGORIES, ADMIN_DELETE_CATEGORY, ADMIN_EDIT_CATEGORY } from '../../redux/api/constants';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import AdminUserList from './userList';
import withLayout from '../withLayout';
import EditCategoryModal from './editCategory';

function EditableCategoryList({ categories, onEdit, onDelete }) {
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryToDelete, setCategoryToDelete] = useState(null);

    const handleDeleteClick = (category) => {
        setCategoryToDelete(category);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        onDelete(categoryToDelete);
        setShowDeleteModal(false);
    };

    const handleEditClick = (category) => {
        setSelectedCategory(category);
        setShowEditModal(true);
    };

    const handleSave = (updatedCategory) => {
        onEdit(updatedCategory);
        setShowEditModal(false);
    };

    return (
        <>
            <ul className="list-group mt-2">
                {categories && categories.map((category, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                        <Link to={`/categories/${category.id}`}>
                            {category.title}
                        </Link>
                        <div>
                            <button className="btn btn-primary me-2" onClick={() => handleEditClick(category)}>Edit</button>
                            <button className="btn btn-danger" onClick={() => handleDeleteClick(category)}>Delete</button>
                        </div>
                    </li>
                ))}
            </ul>
            {selectedCategory && (
                <EditCategoryModal
                    show={showEditModal}
                    handleClose={() => setShowEditModal(false)}
                    category={selectedCategory}
                    handleSave={handleSave}
                />
            )}

            {showDeleteModal && (
                <div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deletion</h5>
                                <button type="button" className="btn-close" onClick={() => setShowDeleteModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                Are you sure you want to delete the category "{categoryToDelete?.title}"?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowDeleteModal(false)}>Cancel</button>
                                <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}


export const Admin = () => {
    const [tab, setTab] = useState(0);
    const dispatch = useDispatch();
    const authToken = useSelector(state => state.Auth.accessToken);
    const me = useSelector(state => state.API.me);
    const categories = useSelector(state => state.API.categories);
    const locator = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        if (authToken) {
            dispatch(doFetch(
                ME,
                'users/me',
                authToken
            ));
            dispatch(doFetch(
                CATEGORIES,
                'categories'
            ));
        }
    }, [dispatch, authToken]);

    useEffect(() => {
        if(locator.pathname === '/admin/categories') {
            setTab(1);
        } else {
            setTab(0);
        }
    })

    const handleTabChange = (tabIndex) => {
        if(tabIndex===1) {
            navigate('/admin/categories')
        } else {
            navigate('/admin')
        }
    };

    const handleEditCategory = (updatedCategory) => {
        dispatch(doPatch(
            ADMIN_EDIT_CATEGORY, 
            `categories/${updatedCategory.id}`, 
            authToken, 
            updatedCategory
        ));
    };
    

    const handleDeleteCategory = (category) => {
        dispatch(doDelete(ADMIN_DELETE_CATEGORY, `categories/${category.id}`, authToken));
    };

    return (
        <div className="container min-vh-100">
            <h1>Admin</h1>
            <ul className="nav nav-tabs mt-3" role="tablist">
                <li className="nav-item" role="presentation">
                    <button
                        className={tab === 0 ? "nav-link active" : "nav-link"}
                        onClick={() => handleTabChange(0)}
                        aria-selected={tab === 0}
                        role="tab">
                        Users
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={tab === 1 ? "nav-link active" : "nav-link"}
                        onClick={() => handleTabChange(1)}
                        aria-selected={tab === 1}
                        role="tab">
                        Categories
                    </button>
                </li>
            </ul>
            <div id="myTabContent" className="tab-content">
                <div className={tab === 0 ? "tab-pane fade active show" : "tab-pane fade"} id="profile" role="tabpanel">
                    <h2 className="lead my-3">Users</h2>
                    <p>System Users</p>
                    <AdminUserList admin={me} />

                </div>
                <div className={tab === 1 ? "tab-pane fade active show" : "tab-pane fade"} id="recipes" role="tabpanel">
                    <h2 className="lead my-3">Categories</h2>
                    <Link className="btn btn-success mb-3" to='/admin/categories/new'>+ Add Category</Link>
                    <EditableCategoryList 
                        categories={categories} user={me} 
                        onEdit={handleEditCategory}
                        onDelete={handleDeleteCategory}
                    />
                </div>
            </div>

        </div>
    );
}

export default withLayout(Admin); 