import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doFetch, doPatch } from '../redux/api/actions';
import { ME, UPDATE_ME } from '../redux/api/constants';
import UserRecipes from './userRecipes';
import withLayout from './withLayout';

const EditableForm = ({ me, onSubmit }) => {
    const [username, setUsername] = useState(me.username);
    const [email, setEmail] = useState(me.email);
    const [firstName, setFirstName] = useState(me.first_name);
    const [lastName, setLastName] = useState(me.last_name);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({
            username,
            email,
            firstName,
            lastName,
        });
    };

    return (
        <div className="container ">
            <form onSubmit={handleSubmit}>
                <fieldset>
                    <div className="form-group row">
                        <label htmlFor="username" className="col-sm-2 col-form-label"><strong>Username:</strong></label>
                        <div className="col-sm-10">
                            <input
                                type="text"
                                className="form-control mt-4"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="email" className="col-sm-2 col-form-label"><strong>Email:</strong></label>
                        <div className="col-sm-10">
                            <input
                                type="email"
                                className="form-control mt-4"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="first_name" className="col-sm-2 col-form-label"><strong>First Name:</strong></label>
                        <div className="col-sm-4">
                            <input
                                type="text"
                                className="form-control mt-4"
                                id="first_name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <label htmlFor="last_name" className="col-sm-2 col-form-label"><strong>Last Name:</strong></label>
                        <div className="col-sm-4">
                            <input
                                type="text"
                                className="form-control mt-4"
                                id="last_name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary">Save</button>
                </fieldset>
            </form>
        </div>
    );
};


function Profile() {
    const dispatch = useDispatch()
    const authToken = useSelector(state => state.Auth.accessToken);
    const me = useSelector(state => state.API.me);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        if (authToken) {
            dispatch(doFetch(
                ME,
                'users/me',
                authToken
            ))
        }
    }, [dispatch, authToken]);

    const updateUser = ({ username, email, firstName, lastName }) => {
        if (!me) {
            return; // Handle the case where `me` is not defined
        }

        const data = {};
        if (email !== undefined && email !== null) {
            data.email = email;
        }
        if (username !== undefined && username !== null) {
            console.log("username is: ", username)
            data.username = username;
        }
        if (firstName !== undefined && firstName !== null) {
            data.first_name = firstName;
        }
        if (lastName !== undefined && lastName !== null) {
            data.last_name = lastName;
        }

        if (Object.keys(data).length > 0 && authToken) {
            dispatch(doPatch(
                UPDATE_ME,
                'users/me',
                authToken,
                data,
            ));
        }
    };

    const handleTabChange = (tabIndex) => {
        setTab(tabIndex);
    };


    return (
        <div>
            <ul className="nav nav-tabs mt-3" role="tablist">
                <li className="nav-item" role="presentation">
                    <button
                        className={tab === 0 ? "nav-link active" : "nav-link"}
                        onClick={() => handleTabChange(0)}
                        aria-selected={tab === 0}
                        role="tab">
                        Profile
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={tab === 1 ? "nav-link active" : "nav-link"}
                        onClick={() => handleTabChange(1)}
                        aria-selected={tab === 1}
                        role="tab">
                        My Recipes
                    </button>
                </li>
            </ul>
            <div id="myTabContent" className="tab-content">
                <div className={tab === 0 ? "tab-pane fade active show" : "tab-pane fade"} id="profile" role="tabpanel">
                    <h2 className="lead my-3">Profile Settings</h2>
                    {me && <EditableForm me={me} onSubmit={updateUser} />}
                </div>
                <div className={tab === 1 ? "tab-pane fade active show" : "tab-pane fade"} id="recipes" role="tabpanel">
                    <h2 className="lead my-3">My Recipes</h2>
                    <p>These are recipes you have submitted in the past</p>
                    {me && <UserRecipes username={me.username} />}
                </div>
            </div>
        </div>
    );
}
export default withLayout(Profile);