import categories from '../../components/categories';
import {
	CATEGORIES,
	CURRENT_CATEGORY,
	CURRENT_RECIPE,
	ERROR,
	FETCH,
	PUT,
	//CREATE,
	PATCH,
	GOT_RESPONSE,
	GET_PUBLIC_USER,
	ME,
	RECIPE_UPDATE,
	RECIPE_CREATE,
	UPDATE_ME,
	ADMIN_GET_USERS,
	ADMIN_UPDATE_USERS,
	ADMIN_DELETE_USER,
	ADMIN_CREATE_CATEGORY,
	ADMIN_EDIT_CATEGORY,
	ADMIN_DELETE_CATEGORY,
	SET_MESSAGE,
	RECIPE_SEARCH
} from './constants';

const initState = {
	loading: false,
	me: null,
	categories: [],
	currentCategoryRecipes: [],
	currentCategoryTitle: "",
	currentCateogryId: "",
	currentRecipe: null,
	recipes: [],
	error: null,
	allUsers: [],
}

const API = (state = initState, action) => {
	switch (action.type) {
		case FETCH:
			if (action.dataType === CURRENT_RECIPE) {
				return { ...state, loading: true, error: null, currentRecipe: null }
			}
			return { ...state, loading: true, error: null }
		case PUT:
			return { ...state, loading: true, error: null }
		case PATCH:
			if (action.dataType === ME) {
				return { ...state, loading: true, error: null }
			}
			return { ...state, loading: true, error: null }
		case GOT_RESPONSE:
			if (action.responseType === ME || action.responseType === UPDATE_ME) {
				return { ...state, loading: false, error: null, me: action.response }
			}
			if (action.responseType === CATEGORIES) {
				return { ...state, loading: false, error: null, categories: action.response.categories }
			}
			if (action.responseType === CURRENT_CATEGORY) {
				return {
					...state, loading: false, error: null,
					currentCategoryTitle: action.response.category_title,
					currentCategoryId: action.response.category_id,
					currentCategoryRecipes: action.response.category_recipes
				}
			}
			if (action.responseType === CURRENT_RECIPE) {
				return { ...state, loading: false, error: null, currentRecipe: action.response.recipe }
			}
			if (action.responseType === RECIPE_UPDATE) {
				return { ...state, loading: false, error: null, currentRecipe: action.response.recipe, message: "Recipe Updated" }
			}
			if (action.responseType === RECIPE_CREATE) {
				return { ...state, loading: false, error: null, currentRecipe: action.response.recipe, message: "Recipe Created" }
			}
			if (action.responseType === GET_PUBLIC_USER) {
				return { ...state, loading: false, error: null, recipes: action.response.recipes }
			}
			if(action.responseType === RECIPE_SEARCH) {
				return { ...state, loading: false, error: null, recipes: action.response.recipes }
			}
			if (action.responseType === ADMIN_GET_USERS) {
				return { ...state, loading: false, error: null, allUsers: action.response.users }
			}
			if (action.responseType === ADMIN_UPDATE_USERS) {
				return { ...state, loading: false, error: null }
			}
			if (action.responseType === ADMIN_DELETE_USER) {
				return { ...state, loading: false, error: null }
			}
			if (action.responseType === ADMIN_CREATE_CATEGORY) {
				return { ...state, loading: false, error: null, categories: [...state.categories, action.response], 
					message: "created new category:" + action.response.title }
			}
			if (action.responseType === ADMIN_EDIT_CATEGORY) {
				return {
					...state,
					loading: false,
					error: null,
					categories: state.categories.map(category =>
						category.id === action.response.id ? action.response : category
					),
					message: "Category updated successfully",
				};
			}
			if (action.responseType === ADMIN_DELETE_CATEGORY) {
				return {
					...state,
					loading: false,
					error: null,
					categories: state.categories.filter(category => category.id !== action.response.category_id),
					message: "Category deleted successfully",
				};
			}
			return { ...state, loading: false, error: "Unimplemented Error" + action.responseType}
		case ERROR:
			return { ...state, loading: false, error: action.error }
		case SET_MESSAGE:
			return { ...state, message: action.message }
		default:
			return state;

	}
};

export default API;