import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RECIPE_SEARCH } from "../redux/api/constants";
import { doFetch } from "../redux/api/actions";
import withLayout from "./withLayout";
import { displayList } from "./recipeList";
import { MakeSearchBar, doSearch } from "./searchbar";

function RecipeSearch() {
    const [keyword, setKeyword] = useState("");
    const search = useLocation().search;
    const dispatch = useDispatch();
    const recipes = useSelector(state => state.API.recipes);
    const navigate = useNavigate();

    useEffect(() => {
        var pkeyword = new URLSearchParams(search).get('keyword');
        pkeyword = decodeURIComponent(pkeyword);
        console.log('decoded email: ', pkeyword);

        if (pkeyword) {
            setKeyword(pkeyword);
        }
    }, [search])

    useEffect(() => {
        if (keyword) {
            dispatch(doFetch(
                RECIPE_SEARCH,
                `recipes?keyword=${keyword}`
            ))
        }
    }, [keyword, dispatch])
    return (
        <>
            <MakeSearchBar searchFunc={(keyword) => doSearch(navigate, keyword)} />
            {displayList(recipes)}
        </>
    );
}

export default withLayout(RecipeSearch);