import {
	AUTH_REQUEST,
	AUTH_SUCCESS,
	AUTH_FAILURE,
	LOGOUT,
	LOGOUT_SUCCESS,
	REGISTER_SUCCESS,
	REGISTER_REQUEST,
	GET_GOOGLE_OAUTH_URL_SUCCESS,
	SEND_GOOGLE_OAUTH_CALLBACK_SUCCESS,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_REQUEST,
} from './constants';
import { jwtDecode } from 'jwt-decode';

var accessToken = sessionStorage.getItem('accessToken');
if (accessToken) {
	const decodedToken = jwtDecode(accessToken);
	const isTokenEspired = decodedToken.exp * 1000 < Date.now();
	if (isTokenEspired) {
		sessionStorage.removeItem('accessToken');
		accessToken = null;
	}
}


const initialState = {
	user: null,
	loading: false,
	communicationMethod: "email",
	message: null,
	error: null,
	accessToken: accessToken,
	oauth_url: null,
};

const Auth = (state = initialState, action) => {
	switch (action.type) {
		case REGISTER_REQUEST:
			return { ...state, user: null, error: null, loading: true, message: null };
		case REGISTER_SUCCESS:
			const message = "Registration successful! Please sign in to continue.";
			return { ...state, user: action.payload, error: null, message: message, loading: false };
		case AUTH_REQUEST:
			return { ...state, user: null, error: null, loading: true, message: null };
		case AUTH_SUCCESS:
			if (action.payload.access_token) {
				sessionStorage.setItem('accessToken', action.payload.access_token);
			}
			return {
				...state,
				user: { ...state.user, ...action.payload },
				accessToken: action.payload.access_token,
				error: null,
				loading: false
			};
		case AUTH_FAILURE:
			return { ...state, user: null, error: action.payload, loading: false };
		case LOGOUT:
		case FORGOT_PASSWORD_REQUEST:
			return { ...state, info: null, error: null, loading: true, message: null };
		case FORGOT_PASSWORD_SUCCESS:
			console.log("Success!")
			return { ...state, message: "PASSWORD_RESET", error: null, loading: false };
		case LOGOUT_SUCCESS:
			sessionStorage.removeItem('accessToken');
			return initialState;
		case GET_GOOGLE_OAUTH_URL_SUCCESS:
			return { ...state, oauth_url: action.payload, error: null, loading: false };
		case SEND_GOOGLE_OAUTH_CALLBACK_SUCCESS:
			if (action.payload.access_token) {
				sessionStorage.setItem('accessToken', action.payload.access_token);
			}
			return { ...state, accessToken: action.payload.access_token, error: null, loading: false };
		default:
			return state;

	}
}

export default Auth;
