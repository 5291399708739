import React, { useState } from 'react';

export function MakeSearchBar({ searchFunc }) {
    const [input, setInput] = useState("");

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            searchFunc(input);
        }
    };
    
    return (
        <div className="input-group my-3">
            <input type="text" 
                    className="form-control" 
                    value={input}
                    onChange={(e)=>setInput(e.target.value)}
                    onKeyDown={handleEnter} //make enter key work
                    placeholder="Search" 
                    aria-label="Search" 
                    aria-describedby="button-addon2"/>
            <button className="btn btn-outline-secondary" 
                    type="button" 
                    onClick={(e)=>searchFunc(input)} 
                    id="button-addon2">Go</button>
        </div>
    )
}

export function doSearch(navigate, keyword) {
    navigate(`/recipes/?keyword=${keyword}`)
}