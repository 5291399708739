
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doFetch } from '../redux/api/actions';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { CURRENT_CATEGORY } from '../redux/api/constants';
import withLayout from './withLayout';

function Categories() {
    const { id } = useParams();
    const dispatch = useDispatch()
    const currentCategoryRecipes = useSelector(state => state.API.currentCategoryRecipes);
    const currentCategoryTitle = useSelector(state => state.API.currentCategoryTitle);

    useEffect(() => {
        dispatch(doFetch(
            CURRENT_CATEGORY,
            `categories/${id}`
        ))
    }, [dispatch, id]);

    return (
        <div>
            <h1 className="mt-5">{currentCategoryTitle}</h1>
            {currentCategoryRecipes && currentCategoryRecipes.map((recipe, index) => (
                <div key={index} className="card mb-3">
                    <div className="row">
                        <div className="col-md-8"> <div className="card-body">
                            <h5 className="card-title">
                                <Link to={`/categories/${id}/recipes/${recipe.id}`}>
                                    {recipe.name}
                                </Link>
                            </h5>
                            <div className="card-text">
                                source: {recipe.source ? recipe.source : "Unknown"}<br />
                                submitted by: <Link to={`/users/${recipe.username}`}>
                                    {recipe.username ? recipe.username : "Unknown"}<br />
                                </Link>
                                last updated: {recipe.last_updated ? recipe.last_updated : "Unknown"}<br />
                            </div>
                        </div>
                        </div>
                        <div className="col-md-4">
                            <img src={recipe.food_image ? recipe.food_image : "/images/placeholder.jpg"} alt={recipe.name} className="img-fluid rounded-start" />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
export default withLayout(Categories);