import React from 'react';
import Routes from './routes';
import "./main.css"

function App() {

  return (
    <Routes />
  );
}

export default App;
