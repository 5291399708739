import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from './auth/constants';
import API from './api/reducers';
import Auth from './auth/reducers';
export const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = {};
  };
  return appReducer(state, action)
}

const appReducer = combineReducers({
  API,
  Auth,
});