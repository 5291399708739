import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendGoogleOAuthCallback } from '../../../redux/auth/actions';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../error';

// state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJmYXN0YXBpLXVzZXJzOm9hdXRoLXN0YXRlIiwiZXhwIjoxNjk4Mzg2NjI0fQ.3_SiXcarjugkFVWL0r3V4qHhWqkb5NOKXCMkF4X9nAs
// &code=4%2F0AfJohXkxbICS7DWqY6rRtaIAaRvNh9swfd-NyW6kQYKSNTE7NM1JDKVRTWfYpAcW7Z6ong
// &scope=email+profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+openid
// &authuser=0
// &prompt=consent

function GoogleCallback() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector(state => state.Auth.accessToken);
    useEffect(() => {
        // Get the query string from the URL
        const queryString = window.location.search;

        // Parse the query string using URLSearchParams
        const queryParams = new URLSearchParams(queryString);

        // Extract specific query parameters
        const state = queryParams.get('state');
        const code = queryParams.get('code');
        const scope = queryParams.get('scope');

        if (code === null || state === null || scope === null) {
            return;
        }
        dispatch(sendGoogleOAuthCallback({ code: code, state: state }))

    }, [dispatch]);

    useEffect(() => {
        if (accessToken) {
            navigate('/');
        }
    }, [accessToken, navigate]);

    return (
        <div className="container mt-5">
            <div className="text-center">
                <ErrorMessage />
                <h3 className="lead">Signing in with Google...</h3>
                <div className="spinner-border" role="status">
                </div>
            </div>
        </div>
    )
}
export default GoogleCallback;

//http://localhost:3000/callback/google?
//state=NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJmYXN0YXBpLXVzZXJzOm9hdXRoLXN0YXRlIiwiZXhwIjoxNjk4Mzg2NjI0fQ.3_SiXcarjugkFVWL0r3V4qHhWqkb5NOKXCMkF4X9nAs&code=4%2F0AfJohXkxbICS7DWqY6rRtaIAaRvNh9swfd-NyW6kQYKSNTE7NM1JDKVRTWfYpAcW7Z6ong&scope=email+profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+openid&authuser=0&prompt=consenteyJhbGciOiJIUzI1