import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doCreate } from '../../redux/api/actions';
import { useNavigate } from "react-router-dom";
import { ADMIN_CREATE_CATEGORY } from '../../redux/api/constants';
import withLayout from '../withLayout';

function AddCategory({ onCategoryAdded }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authToken = useSelector(state => state.Auth.accessToken);
    const [title, updateTitle] = useState("");

    const createCategory = (e) => {
        e.preventDefault();
        dispatch(doCreate(
            ADMIN_CREATE_CATEGORY,
            'categories',
            authToken,
            { title: title },
            navigate('/admin/categories')
        ));
    }

    return (
        <div>
            <h1>Add a new category</h1>
            <form onSubmit={createCategory}>
                <div className="mb-3">
                    <label htmlFor="title" className="form-label">Category Title</label>
                    <input
                        type="text"
                        className="form-control"
                        id="title"
                        value={title}
                        onChange={(e) => updateTitle(e.target.value)}
                    />
                </div>
                <button type="submit" className="btn btn-primary">Add Category</button>
            </form>
        </div>
    );
}

export default withLayout(AddCategory);