export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const SEND_VERIFICATION_CODE_REQUEST = 'SEND_VERIFICATION_CODE_REQUEST';
export const SEND_VERIFICATION_CODE_SUCCESS = 'SEND_VERIFICATION_CODE_SUCCESS';
export const SIGNUP_OAUTH_GOOGLE_REQUEST = 'SIGNUP_OAUTH_GOOGLE_REQUEST';

/* Google OAuth */
export const GET_GOOGLE_OAUTH_URL = 'GET_GOOGLE_OAUTH_URL';
export const GET_GOOGLE_OAUTH_URL_SUCCESS = 'GET_GOOGLE_OAUTH_URL_SUCCESS';
export const SEND_GOOGLE_OAUTH_CALLBACK = 'SEND_GOOGLE_OAUTH_CALLBACK';
export const SEND_GOOGLE_OAUTH_CALLBACK_SUCCESS = 'SEND_GOOGLE_OAUTH_CALLBACK_SUCCESS';