import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doFetch } from '../redux/api/actions';
import { useParams } from "react-router-dom";
import { GET_PUBLIC_USER } from '../redux/api/constants';
import { Link } from 'react-router-dom';
import withLayout from './withLayout';

function PublicProfile() {
    const { username } = useParams();
    const dispatch = useDispatch()
    const recipes = useSelector(state => state.API.recipes);

    useEffect(() => {
        dispatch(doFetch(
            GET_PUBLIC_USER,
            `recipes?username=${username}`
        ))
    }, [dispatch, username]);

    return (
        <>
            <div>
                <h1>{username}</h1>
                <p>Recipes</p>
                {recipes && recipes.map((recipe, index) => (
                    <div key={index} className="card mb-3">
                        <div className="row">
                            <div className="col-md-8"> <div className="card-body">
                                <h5 className="card-title">{recipe.name}</h5>
                                <div className="card-text">
                                    category: <Link to={`/categories/${recipe.category_id}`}>
                                        {recipe.category ? recipe.category : "Unknown"}
                                    </Link><br />
                                    source: {recipe.source ? recipe.source : "Unknown"}<br />
                                    last updated by {recipe.username ? recipe.username : "unknown"} on  {recipe.updated_at ? recipe.updated_at : "Unknown"}<br />
                                </div>
                            </div>
                            </div>
                            <div className="col-md-4">
                                <img src={recipe.food_image ? recipe.food_image : "/images/placeholder.jpg"} alt={recipe.name} className="img-fluid rounded-start" />
                            </div>
                        </div>
                    </div>
                ))
                }
            </div>
        </>
    );
}
export default withLayout(PublicProfile);