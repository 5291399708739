import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authRequest } from '../../redux/auth/actions';
import { useNavigate, Link } from 'react-router-dom';
import SignUpButton from './signupbutton';
import withLayout from '../withLayout';

function SignIn() {
	const dispatch = useDispatch()
	const navigate = useNavigate();
	const accessToken = useSelector(state => state.Auth.accessToken);

	const signInMethod = (params) => {
		dispatch(authRequest(params));
	}

	useEffect(() => {
		if (accessToken) {
			navigate('/');
		}
	}, [accessToken, navigate]);

	return (
		<div className="my-5">
			<h1>Sign In</h1>
			<SignUpButton requestFunc={signInMethod} buttonType="Sign In" />
			<div className="mt-3 text-center">
				<Link to="/auth/forgot">Forgot Password</Link> |
				No Account? <Link to="/signup">Sign Up</Link>
			</div>
		</div>
	)
}

export default withLayout(SignIn);