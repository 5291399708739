import React from "react";
//import { Twitter, Youtube } from "react-bootstrap-icons";

const Footer = () => {
    return (
        <footer className="pt-4 mt-md-5 pt-md-5 footer border-top">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h4>CookingWright</h4>
                        <p>
                            This is a family cookbook
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h4>Links</h4>
                        <ul className="list-unstyled">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                <a href="/about">About</a>
                            </li>
                            <li>
                                <a href="/privacy">Privacy</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h4>The Socials</h4>
                        <a href={`https://twitter.com/pdxtotheworld/`}
                            rel="noopener noreferrer"
                            target="_blank">
                            Twitter
                        </a>
                        &nbsp;
                        <a href="https://www.youtube.com/@maddietotheworld"
                            rel="noopener noreferrer"
                            target="_blank">
                            YouTube
                        </a>
                    </div>
                </div>
            </div>
        </footer >
    );
}

export default Footer;