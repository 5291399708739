import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_PUBLIC_USER } from '../redux/api/constants';
import { doFetch } from '../redux/api/actions';
import { Link } from 'react-router-dom';
import { displayList } from './recipeList';



const UserRecipes = ({ username }) => {

    const dispatch = useDispatch();
    const recipes = useSelector(state => state.API.recipes);
    useEffect(() => {
        dispatch(doFetch(
            GET_PUBLIC_USER,
            `recipes?username=${username}`
        ))
    }, [dispatch, username]);

    return (
        displayList(recipes)
    );

}

export default UserRecipes;