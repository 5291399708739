import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/auth/actions';
import { useNavigate } from 'react-router-dom';

function SignOut() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const accessToken = useSelector(state => state.Auth.accessToken);

    useEffect(() => {
        if (accessToken) {
            dispatch(logout());
            navigate('/');
        }
    }, [accessToken, dispatch, navigate]);

    return (
        <div className="container min-vh-100">
            <h1>Sign Out</h1>
        </div>
    )
}

export default SignOut;