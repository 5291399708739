import {
	AUTH_REQUEST,
	AUTH_SUCCESS,
	AUTH_FAILURE,
	FORGOT_PASSWORD_REQUEST,
	FORGOT_PASSWORD_SUCCESS,
	GET_GOOGLE_OAUTH_URL,
	GET_GOOGLE_OAUTH_URL_SUCCESS,
	SEND_GOOGLE_OAUTH_CALLBACK,
	SEND_GOOGLE_OAUTH_CALLBACK_SUCCESS,
	LOGOUT,
	LOGOUT_SUCCESS,
	REGISTER_REQUEST,
	REGISTER_SUCCESS,
	RESET_PASSWORD_REQUEST,
	RESET_PASSWORD_SUCCESS,
	SEND_VERIFICATION_CODE_REQUEST,
	SEND_VERIFICATION_CODE_SUCCESS,
	SIGNUP_OAUTH_GOOGLE_REQUEST,
} from './constants';

export const registerRequest = (payload) => ({
	type: REGISTER_REQUEST,
	payload
});

export const oauthSignupGoogleRequest = (payload) => ({
	type: SIGNUP_OAUTH_GOOGLE_REQUEST,
	payload
});

export const registerRequestSuccess = (user) => ({
	type: REGISTER_SUCCESS,
	payload: user,
});

export const forgotPasswordRequest = (email) => ({
	type: FORGOT_PASSWORD_REQUEST,
	email,
});
export const forgotPasswordSuccess = () => ({
	type: FORGOT_PASSWORD_SUCCESS,
});


export const resetPasswordRequest = (payload) => ({
	type: RESET_PASSWORD_REQUEST,
	payload,
});

export const resetPasswordSuccess = () => ({
	type: RESET_PASSWORD_SUCCESS,
});

export const sendVerificationCodeRequest = (email) => ({
	type: SEND_VERIFICATION_CODE_REQUEST,
	payload: email,
});
export const sendVerificationCodeSuccess = () => ({
	type: SEND_VERIFICATION_CODE_SUCCESS,
});

export const authRequest = (credentials) => ({
	type: AUTH_REQUEST,
	payload: credentials,
});

export const authSuccess = (user) => ({
	type: AUTH_SUCCESS,
	payload: user,
});

export const authFailure = (error) => ({
	type: AUTH_FAILURE,
	payload: error,
});

export const logout = () => ({
	type: LOGOUT,
});

export const logoutSuccess = () => ({
	type: LOGOUT_SUCCESS,
});

export const getGoogleOauthURL = () => ({
	type: GET_GOOGLE_OAUTH_URL,
});

export const getGoogleOAuthURLSuccess = (url) => ({
	type: GET_GOOGLE_OAUTH_URL_SUCCESS,
	payload: url,
});

export const sendGoogleOAuthCallback = (payload) => ({
	type: SEND_GOOGLE_OAUTH_CALLBACK,
	payload,
});

export const sendGoogleOAuthCallbackSuccess = (payload) => ({
	type: SEND_GOOGLE_OAUTH_CALLBACK_SUCCESS,
	payload,
});