import React from 'react';
import { Link } from 'react-router-dom';

export function displayList(recipes) {
    return (
        <>
            {recipes && recipes.map((recipe, index) => (
                <div key={index} className="card mb-3">
                    <div className="row">
                        <div className="col-md-8"> <div className="card-body">
                            <h5 className="card-title">
                                <Link to={`/categories/${recipe.category.id}/recipes/${recipe.id}`}>{recipe.name}</Link>
                            </h5>
                            <div className="card-text">
                                category: <Link to={`/categories/${recipe.category_id}`}>
                                    {recipe.category ? recipe.category : "Unknown"}
                                </Link><br />
                                source: {recipe.source ? recipe.source : "Unknown"}<br />
                                last updated by {recipe.username ? recipe.username : "unknown"} on  {recipe.updated_at ? recipe.updated_at : "Unknown"}<br />
                            </div>
                        </div>
                        </div>
                        <div className="col-md-4">
                            <img src={recipe.food_image ? recipe.food_image : "/images/placeholder.jpg"} alt={recipe.name} className="img-fluid rounded-start" />
                        </div>
                    </div>
                </div>
            ))
            }
        </>
    )
}