import api_endpoint from '../../settings'


const getHeaders = (accessToken) => {
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
    };
};

const handleFetch = (url, options) => {
    return fetch(url, options)
        .then(response => {
            if (!response.ok) {
                return response.json().then(errorData => {
                    console.log(errorData)
                    const errorMessage = errorData.detail || errorData.message || response.statusText;
                    throw new Error(`Error ${response.status}: ${errorMessage}`);
                });
            }

            // for user deletion requests where it doesn't return anything. 
            if (response.status === 204) {
                // Handle 204 No Content response
                return { message: 'Request was successful, no content returned.' };
            }

            return response.json();
        })
        .catch(error => {
            console.error('Fetch error:', error);
            throw error;
        });
};


const api = {
    get(data) {
        var url = api_endpoint + "/" + data.path
        return handleFetch(url, {
            method: 'GET',
            headers: getHeaders(data.accessToken)
        })
    },
    create(data) {
        /* data.path = register, etc. 
           data.body = json object with key value: {"foo", "bar"}
        */
        var url = api_endpoint + "/" + data.path
        return handleFetch(url, {
            method: 'POST',
            headers: getHeaders(data.accessToken),
            body: JSON.stringify(data.data)
        })
    },
    update(data) {
        var url = api_endpoint + "/" + data.path
        return handleFetch(url, {
            method: 'PUT',
            headers: getHeaders(data.accessToken),
            body: JSON.stringify(data.data)
        })
    },
    patch(data) {
        var url = api_endpoint + "/" + data.path
        return handleFetch(url, {
            method: 'PATCH',
            headers: getHeaders(data.accessToken),
            body: JSON.stringify(data.data)
        })
    },
    delete(data) {
        var url = api_endpoint + "/" + data.path
        return handleFetch(url, {
            method: 'DELETE',
            headers: getHeaders(data.accessToken)
        })
    }
}

export default api;