import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doDelete, doFetch, doPatch } from '../../redux/api/actions';
import { ADMIN_DELETE_USER, ADMIN_GET_USERS, ADMIN_UPDATE_USERS } from '../../redux/api/constants';
import { Link } from 'react-router-dom';

function AdminUserList({ admin }) {
    const dispatch = useDispatch()
    const authToken = useSelector(state => state.Auth.accessToken);
    const userListFromStore = useSelector(state => state.API.allUsers);
    const [userList, setUserList] = useState([]);


    useEffect(() => {
        if (authToken) {
            dispatch(doFetch(
                ADMIN_GET_USERS,
                'users',
                authToken
            ))
        }
    }, [dispatch, authToken]);

    useEffect(() => {
        if (userListFromStore) {
            setUserList(userListFromStore)
        }
    }, [userListFromStore])


    function updateUser(e, user, attribute) {
        const updatedUserList = userList.map((u) => {
            if (u.id === user.id) {
                return {
                    ...u,
                    [attribute]: e.target.checked
                }
            }
            return u
        })
        setUserList(updatedUserList)
        dispatch(doPatch(
            ADMIN_UPDATE_USERS,
            `users/${user.id}`,
            authToken,
            { [attribute]: e.target.checked, first_name: user.first_name, last_name: user.last_name, username: user.username, email: user.email }
        ))
    }

    function deleteUser(user) {
        dispatch(doDelete(
            ADMIN_DELETE_USER,
            `users/${user.id}`,
            authToken
        ))
    }

    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Username</th>
                    <th scope="col">Email</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Admin?</th>
                    <th scope="col">Verified?</th>
                    <th scope="col">Active?</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                {userList && userList.map((user, index) => (

                    <tr key={index} >
                        <td><Link to={`/users/${user.username}`}>{user.username}</Link></td>
                        <td>{user.email}</td>
                        <td>{user.first_name}</td>
                        <td>{user.last_name}</td>
                        <td>
                            {/* check box form to update user */}
                            <form >
                                <input type="checkbox" name="is_admin" checked={!!user.is_superuser} onChange={(e) => updateUser(e, user, "is_superuser")} />
                            </form>
                        </td>

                        <td>
                            <form >
                                <input type="checkbox" name="is_verified" checked={!!user.is_verified} onChange={(e) => updateUser(e, user, "is_verified")} />
                            </form>
                        </td>
                        <td>
                            <form >
                                <input type="checkbox" name="is_active" checked={!!user.is_active} onChange={(e) => updateUser(e, user, "is_active")} />
                            </form>
                        </td>
                        <td>
                            <button className="btn btn-danger" onClick={() => deleteUser(user)}>Delete</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table >
    )

}

export default AdminUserList;