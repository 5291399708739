import React from "react";
import { useSelector } from 'react-redux';

function Spinner() {
    const loading = useSelector(state => state.Auth.loading);
    return (
        loading &&
        <div className="spinner-border" role="status" />
    );
}

export default Spinner
