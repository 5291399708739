import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doFetch } from '../redux/api/actions';
import { ME } from '../redux/api/constants';
import withLayout from './withLayout';

function About() {
    const dispatch = useDispatch()
    const authToken = useSelector(state => state.Auth.accessToken);

    useEffect(() => {
        if (authToken) {
            dispatch(doFetch(
                ME,
                'users/me',
                authToken
            ))
        }
    }, [dispatch, authToken]);

    return (
        <div>
            <h1>Welcome to Cooking Wright</h1>
            CookingWright is a family cookbook with all the recipes we like.
        </div>
    );
}
export default withLayout(About);